<template>
	<div>
		<image-dropdown-component
			name="Kel'Thuzad"
			logoPath="/assets/wow/kel-thuzad.png">
			<template>
				<div>
					<v-card-title style="display: block"> Kel'Thuzad </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>
								Phase 1: Everyone should be standing in the middle of the room to avoid body pulling the trash in the
								chambers.
							</li>
							<li>
								Kill off any adds that starts running towards the middle. Be careful with your pets so they dont pull more
								adds.
							</li>
							<li>
								Phase 2: Kel'Thuzad becomes attackable. Tank him in the Center of the room. The rest of the raid should be
								spread out to minimize how many gets hit by Frost Blast.
							</li>
							<li>Move out of Shadow Fissures if you get targeted by them (purple circles on ground)</li>
							<li>Run away 10+ yards from people if you are targeted by Detonate Mana</li>
							<li>
								Players will occassionally get Mind Controlled for 20 seconds, these players can be stunned and crowd
								controlled
							</li>
							<li>
								When Kel'Thuzad reaches 45% HP, Guardians of Icecrown will spawn, the off tank will pick them up and tank
								them away from the raid. The rest of the group should ignore them and focus Kel'Thuzad
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>When Kel'Thuzad reaches 45% hp, the off tank should pick up the adds and tank them away from the raid.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Focus healing on the players that gets hit by Frost Blast</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Kel'Thuzad's Frostbolt can be interrupted in Phase 2</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
