<template>
	<div>
		<image-dropdown-component
			name="Loatheb"
			logoPath="/assets/wow/loatheb.png">
			<template>
				<div>
					<v-card-title style="display: block"> Loatheb </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Tank the boss in the middle of the room.</li>
							<li>
								Killing the spores will grant a debuff to 5 nearby players, giving them 50% crit chance and less threat
								generation.
							</li>
							<li>
								Assign 4 groups, containing everyone except the Tanks. These groups should (one at a time) run up the spore,
								wait for everyone in the group, then kill it off.
							</li>
							<li>
								Most of the encounter, you will not be able to receive any healing, make sure to help your healers by using
								personal healing in the short windows where the healing debuff is gone
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Make sure you are not close to a spore that is about to die</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Shields etc. are very effective in this fight since they are not affected by the healing debuff</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Nothing special here, refer to the general information</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
