<template>
	<div>
		<image-dropdown-component
			name="Patchwerk"
			logoPath="/assets/wow/patchwerk.png">
			<template>
				<div>
					<v-card-title style="display: block"> Patchwerk </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Make sure you clear the whole room before pulling</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Tank Patchwerk close to the green slime to allow melee DPS to quickly dip into the slime</li>
							<li>
								Off tank should try to keep health as high as possible to make sure that you get targeted by Hateful Strike
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Focus healing on the Main and Off tank</li>
							<li>Be careful not to heal a melee too much so that their HP exceeds the offtank.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>
								Melee DPS should dip quickly in and out of the slime to lower their HP so that they won't get targeted by
								Hateful Strike
							</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
