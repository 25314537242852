<template>
	<div>
		<image-dropdown-component
			name="Gothik"
			logoPath="/assets/wow/gothik-the-harvester.png">
			<template>
				<div>
					<v-card-title style="display: block"> Gothik the Harvester </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>
								The fight is split into 2 different rooms. However, your entire raid should stay in the first room. The gate
								will open after 2 and a half minute and you should use AoE cooldowns and burst the adds down.
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Be ready to generate threat once the middle gate opens, also use your damage mitigation here.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Nothing special here.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Make sure you have CDs for the gate opening after 2 and a half minute.</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
