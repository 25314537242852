<template>
	<div
		class="text-left"
		style="padding-left: 1rem">
		<div style="display: inline-block; width: 100%">
			<h2>The Arachnid Quarter</h2>
			<anub-rekhan-component />
			<grand-widow-faerlina-component />
			<maexxna-component /><br />
		</div>
		<div style="display: inline-block; width: 100%">
			<h2>The Plague Quarter</h2>
			<noth-the-plaguebringer-component />
			<heigan-the-unclean-component />
			<loatheb-component />
		</div>
		<div style="display: inline-block; width: 100%">
			<h2>The Military Quarter</h2>
			<instructor-razuvious-component />
			<gothik-the-harvester-component />
			<the-four-horsemen-component />
		</div>
		<div style="display: inline-block; width: 100%">
			<h2>The Construct Quarter</h2>
			<patchwerk-component />
			<grobbulus-component />
			<gluth-component />
			<thaddius-component />
		</div>
		<div style="display: inline-block; width: 100%">
			<h2>Frostwyrm Lair</h2>
			<sapphiron-component />
			<kel-thuzad-component />
		</div>
	</div>
</template>

<script>
	import AnubRekhanComponent from '../wowclassic/naxxramas/AnubRekhanComponent.vue';
	import GrandWidowFaerlinaComponent from '../wowclassic/naxxramas/GrandWidowFaerlinaComponent.vue';
	import MaexxnaComponent from '../wowclassic/naxxramas/MaexxnaComponent.vue';
	import NothThePlaguebringerComponent from '../wowclassic/naxxramas/NothThePlaguebringerComponent.vue';
	import HeiganTheUncleanComponent from '../wowclassic/naxxramas/HeiganTheUncleanComponent.vue';
	import LoathebComponent from '../wowclassic/naxxramas/LoathebComponent.vue';
	import InstructorRazuviousComponent from '../wowclassic/naxxramas/InstructorRazuviousComponent.vue';
	import GothikTheHarvesterComponent from '../wowclassic/naxxramas/GothikTheHarvesterComponent.vue';
	import TheFourHorsemenComponent from '../wowclassic/naxxramas/TheFourHorsemenComponent.vue';
	import PatchwerkComponent from '../wowclassic/naxxramas/PatchwerkComponent.vue';
	import GrobbulusComponent from '../wowclassic/naxxramas/GrobbulusComponent.vue';
	import GluthComponent from '../wowclassic/naxxramas/GluthComponent.vue';
	import ThaddiusComponent from '../wowclassic/naxxramas/ThaddiusComponent.vue';
	import SapphironComponent from '../wowclassic/naxxramas/SapphironComponent.vue';
	import KelThuzadComponent from '../wowclassic/naxxramas/KelThuzadComponent.vue';
	export default {
		components: {
			PatchwerkComponent,
			AnubRekhanComponent,
			GrandWidowFaerlinaComponent,
			MaexxnaComponent,
			NothThePlaguebringerComponent,
			HeiganTheUncleanComponent,
			LoathebComponent,
			InstructorRazuviousComponent,
			GothikTheHarvesterComponent,
			TheFourHorsemenComponent,
			GrobbulusComponent,
			GluthComponent,
			ThaddiusComponent,
			SapphironComponent,
			KelThuzadComponent,
		},

		metaInfo() {
			return {
				title: 'Gaming QoL',
				titleTemplate: '%s | Wow Classic Raid Guides',
				htmlAttrs: {
					lang: 'en',
				},
				meta: [
					{ charset: 'utf-8' },
					{
						vmid: 'description',
						name: 'description',
						content: 'Guides for all bosses in Naxxramas 10 and 25 man. :)',
					},
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1',
					},
				],
			};
		},
	};
</script>
