<template>
	<div id="container">
		<v-data-table
			:headers="headers"
			:items="servers"
			:items-per-page="10"
			class="elevation-4"
			@click:row="handleClick"></v-data-table>
		{{ info }}
		<ServerDialogComponent
			:visible="showDialog"
			:selected="selected"
			@close="showDialog = false" />
	</div>
</template>

<script>
	import axios from 'axios';
	import { ref } from 'vue';
	import ServerDialogComponent from './ServerDialogComponent.vue';
	const ROWS = 100;
	const GAME = 'rust';
	const SORT = '-players';
	const URL_PATH = `https://api.battlemetrics.com/servers?filter[game]=${GAME}&page[size]=${ROWS}&sort=${SORT}`;

	export default {
		components: {
			ServerDialogComponent,
		},
		setup() {
			const info = ref(null);
			const headers = ref([
				{
					text: 'Server Name',
					align: 'start',
					sortable: true,
					value: 'name',
				},
				{ text: 'Players', value: 'players' },
				{ text: 'Amount in Queue', value: 'amountInQueue' },
				{ text: 'Last Wiped', value: 'wipeDate', sortable: false },
			]);
			const servers = ref([]);
			const selected = ref({});
			const showDialog = ref(false);

			const handleClick = (row) => {
				showDialog.value = true;
				selected.value = row;
			};

			const timeSince = (date) => {
				const intervals = [
					{ label: 'year', seconds: 31536000 },
					{ label: 'month', seconds: 2592000 },
					{ label: 'day', seconds: 86400 },
					{ label: 'hour', seconds: 3600 },
					{ label: 'minute', seconds: 60 },
					{ label: 'second', seconds: 1 },
				];

				const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
				const interval = intervals.find((i) => i.seconds < seconds);
				const count = Math.floor(seconds / interval.seconds);
				return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
			};

			return {
				info,
				headers,
				servers,
				selected,
				showDialog,
				handleClick,
				timeSince,
			};
		},
		mounted() {
			if (process.env.NODE_ENV === 'development') {
				axios.get(URL_PATH).then((response) => {
					let serverList = response.data.data;
					serverList.forEach((server) => {
						let serverConverted = {
							name: server.attributes.name,
							players: server.attributes.players,
							amountInQueue: server.attributes.details.rust_queued_players,
							wipeDate: new Date(server.attributes.details.rust_last_wipe),
							address: server.attributes.ip,
							website: server.attributes.details.rust_url,
							country: server.attributes.country,
						};
						this.servers.push(serverConverted);
					});
					this.servers.sort((a, b) => {
						return new Date(a.wipeDate).getTime() < new Date(b.wipeDate).getTime() ? 1 : -1;
					});
					this.servers.forEach((server) => {
						server.wipeDate = this.timeSince(server.wipeDate);
					});
				});
			}
		},
	};
</script>
