<template>
	<div class="home">
		<valorant-component />
	</div>
</template>

<script>
import ValorantComponent from '../../client/components/valorant/ValorantComponent.vue';
export default {
	components: { ValorantComponent },
	name: 'Valorant',
};
</script>
