<template>
	<div class="text-left">
		<v-card style="background-color: #eaeaea">
			<v-card-title>
				{{ role }}
			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	export default {
		props: {
			role: { type: String },
		},
	};
</script>
