<template>
	<div>
		<image-dropdown-component
			name="Thaddius"
			logoPath="/assets/wow/thaddius.png">
			<template>
				<div>
					<v-card-title style="display: block"> Thaddius </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Phase 1: Split the raid into 2 groups, one group on each side</li>
							<li>The 2 bosses needs to die within 10 seconds of each other</li>
							<li>
								Phase 2: You will jump down to the boss platform (it is possible to fall down all the way if you are not
								careful).
							</li>
							<li>
								You will gain a debuff with either a minus or a plus icon, all members needs to stack together with the
								other players that have the SAME debuff. To make it simple, assign which side of the boss each debuff stands
								on (for instance, "- left + right", which means that people with the minus debuff stands on the left side of
								the platform and the people with the plus icon stands on the right side)
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>In phase 1, make sure the bosses dont leave their platforms</li>
							<li>Be ready to taunt when you get swapped to the other platform</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Nothing special here, refer to the general information</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Nothing special here, refer to the general information</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
