<template>
	<div class="text-left">
		<v-container fluid>
			<image-route-component
				name="Raid Guides"
				logoPath="/assets/wow/raidguides.png"
				path="/raidguides" />
		</v-container>
	</div>
</template>

<script>
	import ImageRouteComponent from '../static/ImageRouteComponent.vue';
	export default {
		components: {
			ImageRouteComponent,
		},

		metaInfo() {
			return {
				title: 'Gaming QoL',
				titleTemplate: '%s | Wow Classic',
				htmlAttrs: {
					lang: 'en',
				},
				meta: [
					{ charset: 'utf-8' },
					{
						vmid: 'description',
						name: 'description',
						content: 'Working description.',
					},
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1',
					},
				],
			};
		},
	};
</script>
