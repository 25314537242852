<template>
	<router-link
		:to="this.path"
		style="color: black">
		<div id="gameContainer">
			<v-avatar
				color="indigo"
				size="128">
				<v-img :src="this.logoPath" />
			</v-avatar>
			<p class="text-center text-h5">{{ this.name }}</p>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			name: { type: String },
			logoPath: { type: String },
			path: { type: String },
		},
	};
</script>
