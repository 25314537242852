<template>
	<div>
		<image-dropdown-component
			name="Noth"
			logoPath="/assets/wow/noth-the-plaguebringer.png">
			<template>
				<div>
					<v-card-title style="display: block"> Noth the Plaguebringer </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Make sure to take aggro immediately after Noth uses Blink</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Tank Patchwerk close to the green slime to allow melee DPS to quickly dip into the slime</li>
							<li>
								Off tank should try to keep health as high as possible to make sure that you get targeted by Hateful Strike
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Dispel the Cripple debuff</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Be careful with aggro right after Noth uses Blink</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
