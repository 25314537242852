<template>
	<div>
		<image-dropdown-component
			name="Heigan"
			logoPath="/assets/wow/heigan-the-unclean.png">
			<template>
				<div>
					<v-card-title style="display: block"> Heigan the Unclean </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>The fight consists of 2 phases</li>
							<li>
								In phase 1, ranged should be standing on the platform, make sure you are more than 20 yards away from the
								boss so you dont get hit by the debuff.
							</li>
							<li>The room is "split" into 4 sections, the part of the room where you enter is called section 1.</li>
							<li>
								During phase 1, three of the sections will erupt, dealing damage to everyone in it, you need to make sure
								that you are in the section that isnt going to erupt.
							</li>
							<li>In phase 1, the safe sections are the following: 1-2-3-4-3-2-1-2</li>
							<li>
								In phase 2. Ranged should get down from the platform and join the rest of the raid in dodging the eruptions
							</li>
							<li>In phase 2, the safe sections are the following: 1-2-3-4-3-2-1-2-3-4-3-2</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>
								Do not tank the boss too close to the platform. The ranged must be able to stand on the platform more than
								20 yards away from the boss
							</li>
							<li>Kite the boss along the safe sections</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Dispel the players that gets affected by Decrepit Fever (Disease)</li>
							<li>If hit by the casting debuff, try to use instant casts instead.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Nothing special here, refer to the General Information</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
