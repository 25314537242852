<template>
	<div>
		<image-dropdown-component
			name="Anub'Rekhan"
			logoPath="/assets/wow/anubrekhan.png">
			<template>
				<div>
					<v-card-title style="display: block"> Anub'Rekhan </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Tank the boss far away in the room.</li>
							<li>
								Ranged spread out on the sides to minimize how many gets hits by Impale. Healers should be middle of the
								spread to make sure you can reach everyone
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Face the boss away from the raid</li>
							<li>
								Pick up the adds that spawns during the encounter, tank them close to the boss to allow faster kill with
								cleave
							</li>
							<li>
								Move the boss during Locust Swarm, be careful with the Crypt Guards so you dont face them towards the raid.
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>
								Keep an extra eye out for the people that gets hit by Impale. The fall damage might kill them so any insta
								heal/shield is great!
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>
								When a Crypt Guard is about to die, it will spawn 10 small adds. Kill those as soon as possible to make sure
								they dont aggro on the healers.
							</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
