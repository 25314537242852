<template>
	<div>
		<image-dropdown-component
			name="Sapphiron"
			logoPath="/assets/wow/sapphiron.png">
			<template>
				<div>
					<v-card-title style="display: block"> Sapphiron </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>
								Tank Sapphiron in the middle, faced away. Rest of raid spreads on each side of Sapphiron to avoid as many
								getting hit by Blizzard
							</li>
							<li>
								Sapphiron will fly up in the air after 45 seconds, and players will get stuck inside ice blocks, the
								remaining of the raid line of sights Sapphiron with the Ice Block to avoid Frost Breath.
							</li>
							<li>Once Sapphiron lands, the fight is just rince and repeat</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Make sure you dont face Sapphiron's tail towards the raid</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Dispel Life Drain (curse) whenever it is applied</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>
								You can DPS Sapphiron while she is in the air, just make sure you stand behind an Ice Block when she casts
								Frost Breath
							</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
