<template>
	<v-app>
		<div id="nav">
			<header-component />
			<div style="float: left; width: 100%">
				<v-btn
					color="primary"
					style="float: left; margin: 16px"
					@click="$router.go(-1)"
					v-if="!isBackButtonHidden()">
					<v-icon
						x-large
						style="float: left"
						>mdi-arrow-left </v-icon
					>Back</v-btn
				>
			</div>
			<router-view />
		</div>
	</v-app>
</template>

<script>
	import HeaderComponent from './components/static/HeaderComponent.vue';
	import './css/app.css';
	import { ref } from 'vue';

	export default {
		components: { HeaderComponent },
		name: 'App',
		metaInfo() {
			return {
				title: 'Gaming Quality of Life',
				titleTemplate: '%s',
				htmlAttrs: {
					lang: 'en',
				},
				meta: [
					{ charset: 'utf-8' },
					{
						vmid: 'description',
						name: 'description',
						content:
							'A Hub that collects all different kinds of information about many games on the same place. The goal is that you should have an easier time navigating to different guides/forums and have all the information available from the same place',
					},
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1',
					},
				],
			};
		},
		setup() {
			const isBackButtonHidden = () => {
				return window.location.pathname === '/';
			};

			return {
				isBackButtonHidden,
			};
		},
	};
</script>
