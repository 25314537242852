<template>
	<div>
		<image-dropdown-component
			name="Maexxna"
			logoPath="/assets/wow/maexxna.png">
			<template>
				<div>
					<v-card-title style="display: block"> Maexxna </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Tank the boss in the middle of the room, faced away from the entrance. Melee DPS stands behind.</li>
							<li>Make sure you don't pop CDs right before web spray as it stuns the whole raid for a couple of seconds</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Use damage mitigation right before Web Spray.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Dispel Necrotic Poison as fast as you can</li>
							<li>Top off the Main Tank right before Web Spray</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Assign ranged DPS that should break free the cocooned player.</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
