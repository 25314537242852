<template>
	<div>
		<image-dropdown-component
			name="Gluth"
			logoPath="/assets/wow/gluth.png">
			<template>
				<div>
					<v-card-title style="display: block"> Gluth </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>The fight starts as soon as you jump down from the pipe</li>
							<li>
								The fight is mostly about damaging down Gluth until he casts Decimate, then you need to shift your focus to
								killing all of the Zombie Chows. Rince and repeat
							</li>
							<li>Kite Gluth away to the far end of the room, DPS should wait with dealing damage until Gluth is in place</li>
							<li>
								Assign a player that should take aggro and kite all of the Zombie Chows. The off tank or a frost mage with
								Improved Blizzard are great candidates. Make sure that the assigned player kites/tanks the Zombie Chows far
								away from the boss
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Main Tank should tank Gluth at the far end of the room</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>
								When Gluth casts Decimate, the whole raid will lose 95% of their health. Be ready to heal everyone up when
								this happens
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Swap focus to killing all Zombie Chows once Gluth casts Decimate</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
