<template>
	<div class="headerContainer">
		<v-app-bar
			color="primary"
			dark>
			<router-link to="/">
				<v-toolbar-title class="white--text">Gaming QoL</v-toolbar-title>
			</router-link>

			<div style="position: absolute; right: 0.5rem">
				<v-toolbar-title class="white--text">{{ version }}</v-toolbar-title>
			</div>
		</v-app-bar>
	</div>
</template>

<script>
	import { ref } from 'vue';

	export default {
		setup() {
			const version = ref(process.env.VUE_APP_VERSION);
			const drawer = ref(false);
			const group = ref(null);

			return {
				version,
				drawer,
				group,
			};
		},
	};
</script>

<style lang="scss">
	header {
		display: flex;
		border-bottom: 1px solid #ccc;
		padding: 0.5rem 1rem;

		p {
			margin-left: 1rem;
		}
	}

	nav {
		margin-left: auto;

		ul {
			list-style: none;
		}

		ul li {
			display: inline-flex;
			margin-left: 1rem;
		}
	}
</style>
