<template>
	<v-dialog
		v-model="show"
		max-width="900px">
		<v-card>
			<v-card-title>
				{{ selected.name }}
			</v-card-title>
			<v-card-text>
				<div class="text-left">client.connect {{ selected.address }}</div>
			</v-card-text>
			<v-card-text>
				<div class="text-left">Website: {{ selected.website }}</div>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="primary"
					@click.stop="show = false"
					>Close</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		props: ['visible', 'selected'],
		computed: {
			show: {
				get() {
					return this.visible;
				},
				set(value) {
					if (!value) {
						this.$emit('close');
					}
				},
			},
		},
	};
</script>
