<template>
	<div class="home">
		<wow-classic-naxxramas-component />
	</div>
</template>

<script>
	import WowClassicNaxxramasComponent from '../../client/components/wowclassic/WowClassicNaxxramasComponent.vue';
	export default {
		components: { WowClassicNaxxramasComponent },
		name: 'WowClassic',
	};
</script>
