<template>
	<div class="home">
		<wow-classic-raid-guides-component />
	</div>
</template>

<script>
	import WowClassicRaidGuidesComponent from '../../client/components/wowclassic/WowClassicRaidGuidesComponent.vue';
	export default {
		components: { WowClassicRaidGuidesComponent },
		name: 'WowClassic',
	};
</script>
