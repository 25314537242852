<template>
	<div>
		<image-dropdown-component
			name="Grobbulus"
			logoPath="/assets/wow/grobbulus.png">
			<template>
				<div>
					<v-card-title style="display: block"> Grobbulus </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>The boss is possible with 1 tank</li>
							<li>
								If you get targeted by Mutating Injection, then run out of the raid behind the boss and wait for dispel
								before you run back.
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>
								Tank the boss along the edge of the room, kiting him around as the raid drops Mutating Injection behind the
								boss.
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Make sure you wait with dispelling Mutating Injection until the target has run out of the raid.</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Swap to any Fallout Slime that spawns</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
