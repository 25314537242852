<template>
	<div>
		<image-dropdown-component
			name="Razuvious"
			logoPath="/assets/wow/instructor-razuvious.png">
			<template>
				<div>
					<v-card-title style="display: block"> Instructor Razuvious </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>
								Bring at least 2 priests for 25-man if possible. They will keep 2 understudies mind controlled which will
								then tank Razuvious
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>
								In 10-man, both tanks take one controlling orb each and tanks Razuvious for the remaining of the encounter.
							</li>
							<li>
								In 25-man, you are supposed to tank the understudies that aren't currently mind controlled. Make sure you
								keep aggro on Razuvious on pull though until an understudies takes aggro from you
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>The whole raid will periodically take damage during the encounter</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Just focus on doing damage, nothing to be worried about.</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
