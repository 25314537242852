import Vue from 'vue';
import App from './client/App.vue';
import vuetify from './client/plugins/vuetify';
import router from './router';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

new Vue({
	vuetify,
	VueMeta,
	router,
	render: (h) => h(App),
	mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
