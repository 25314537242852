<template>
	<div class="text-left">
		<v-container fluid>
			<v-row class="fill-height">
				<v-col
					cols="12"
					lg="6"
					class="d-flex"
					style="flex-direction: column">
					<v-card class="ma-1 mb-1 flex-grow-1">
						<v-card-title> Aim </v-card-title>
						<v-card-text>
							<a href="https://www.youtube.com/watch?v=SFtywqoW9lA">THE SECRET VALORANT AIM METHOD (routine) </a>
							<br />
							<a href="https://www.boostingfactory.com/valorant/blog/valorant-aim-guide-top-10-tips-improving-your-aim">
								Top 10 Tips On Improving Your Aim
							</a>
							<br />
							<a href="https://www.redbull.com/ca-en/valorant-aiming-tips-guide">
								7 tips for improving your aim in Valorant
							</a>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					lg="6"
					class="d-flex"
					style="flex-direction: column">
					<v-card class="ma-1 mb-1 flex-grow-1">
						<v-card-title> Gamesense </v-card-title>
						<v-card-text>
							Training game sense comes down to the following points:
							<ul>
								<li>Playing the game</li>
								<li>
									<a href="https://www.youtube.com/watch?v=0pH0-9MZBgg">Recording and reviewing your own gameplay </a>
								</li>
								<li>Studying professional players (by watching streams or pro matches)</li>
							</ul>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		metaInfo() {
			return {
				title: 'Gaming Quality of Life',
				titleTemplate: '%s | Valorant',
				htmlAttrs: {
					lang: 'en',
				},
				meta: [
					{ charset: 'utf-8' },
					{
						vmid: 'description',
						name: 'description',
						content:
							'Want to aim like Tenz or clutch like Hiko? Here are a collection of the most useful resources available about Valorant. Aim like a pro or think like a pro, both parts are very important.',
					},
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1',
					},
				],
			};
		},
	};
</script>
