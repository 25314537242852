<template>
	<div>
		<game-component
			v-for="game in games"
			:key="game.name"
			:item="game"
			:name="game.name"
			:logoPath="game.logoPath"
			:path="game.path" />
	</div>
</template>

<script>
	import GameComponent from './GameComponent.vue';

	export default {
		components: {
			GameComponent,
		},
		setup() {
			const games = [
				{
					name: 'Wow Classic',
					logoPath: '/assets/wowclassic.png',
					path: '/wowclassic',
				},
				{
					name: 'Valorant',
					logoPath: '/assets/valorant.jpg',
					path: '/Valorant',
				},
			];

			// Only show Rust in the Development environment
			if (process.env.NODE_ENV === 'development') {
				games.push({
					name: 'Rust',
					logoPath: '/assets/rustlogo.png',
					path: '/Rust',
				});
			}

			return {
				games,
			};
		},

		methods: {},
	};
</script>
