<template>
	<div
		:id="isDisabled ? 'gameContainerDisabled' : 'gameContainer'"
		@click="handleClick">
		<v-avatar
			color="indigo"
			size="128">
			<v-img :src="this.logoPath" />
		</v-avatar>
		<p class="text-center text-h5">{{ this.name }}</p>
		<v-dialog
			v-model="show"
			max-width="900px">
			<v-card style="background-color: #eaeaea">
				<div>
					<slot></slot>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { ref } from 'vue';
	export default {
		setup(props) {
			const handleClick = () => {
				if (props.disabled) {
					return;
				}
				show.value = true;
			};

			const show = ref(false);
			const isDisabled = ref(props.disabled);
			return {
				handleClick,
				show,
				isDisabled,
			};
		},
		props: {
			name: { type: String },
			logoPath: { type: String },
			disabled: { type: String },
		},
	};
</script>
