import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

import { routes } from './routes.js';

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
	routes,
	mode: 'history',
});

export default router;
