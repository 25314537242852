<template>
	<div class="home">
		<wow-classic-component />
	</div>
</template>

<script>
import WowClassicComponent from '../../client/components/wowclassic/WowClassicComponent.vue';
export default {
	components: { WowClassicComponent },
	name: 'WowClassic',
};
</script>
