<template>
	<div>
		<image-dropdown-component
			name="Horsemen"
			logoPath="/assets/wow/the-four-horsemen.png">
			<template>
				<div>
					<v-card-title style="display: block"> The Four Horsemen </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>
								At the start of the fight, stack Thane Korth'azz and Baron Rivendare together and pop cooldowns and burn
								them down (focus Thane) asap before your stacks gets too high. If you reach 4+ stacks, then you need to run
								60 yards away and wait for the debuff to drop before going back.
							</li>
							<li>
								2 healers will each go to the corners of Lady Blaumeux and Sir Zeliek and tank the bosses while the rest of
								the raid deals with Thane and Baron. Swap between each other if you reach 4+ stacks
							</li>
							<li>Once Thane and Baron are dead, go kill Lady Blaumeux and lastly kill Sir Zeliek</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Nothing special here, refer to the general information</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Nothing special here, refer to the general information</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>Nothing special here, refer to the general information</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
