import Home from '../views/static/Home.vue';
import RustBrowser from '../views/rust/RustBrowser.vue';
import Valorant from '../views/valorant/Valorant.vue';
import WowClassic from '../views/wowclassic/WowClassic.vue';
import RaidGuides from '../views/wowclassic/RaidGuides.vue';
import Naxxramas from '../views/wowclassic/Naxxramas.vue';

export const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/rust',
		name: 'Rust',
		component: RustBrowser,
	},
	{
		path: '/Valorant',
		name: 'Valorant',
		component: Valorant,
	},
	{
		path: '/wowclassic',
		name: 'Wow Classic',
		component: WowClassic,
	},
	{
		path: '/wowclassic/raidguides',
		name: 'Wow Classic - Raid Guides',
		component: RaidGuides,
	},
	{
		path: '/wowclassic/raidguides/naxxramas',
		name: 'Wow Classic - Raid Guides',
		component: Naxxramas,
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/static/About.vue'),
	},
];
