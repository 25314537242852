<template>
	<div class="home">
		<home-component />
	</div>
</template>

<script>
import HomeComponent from '../../client/components/static/HomeComponent.vue';

export default {
	components: { HomeComponent },
	name: 'Home',
};
</script>
