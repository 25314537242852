<template>
	<div class="home">
		<server-list-component />
	</div>
</template>

<script>
import ServerListComponent from '../../client/components/rust/ServerListComponent.vue';
export default {
	components: { ServerListComponent },
	name: 'Home',
};
</script>
