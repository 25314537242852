<template>
	<div>
		<image-dropdown-component
			name="Faerlina"
			logoPath="/assets/wow/grand-widow-faerlina.png">
			<template>
				<div>
					<v-card-title style="display: block"> Grand Widow Faerlina </v-card-title>
					<wow-classic-information-card role="General Information">
						<ul>
							<li>Make sure you clear the whole room before pulling</li>
							<li>Watch out for the Rain of Fire</li>
							<li>
								(10-man) When Faerlina gains Frenzy you need to dispel it by defeating a Naxxramas Follower close to
								Faerlina
							</li>
							<li>
								(25-man) When Faerlina gains Frenzy you need to dispel it by using Mind Control on a Naxxramas Worshipper
								and targeting Faerlina with Widow's Embrace
							</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Tank">
						<ul>
							<li>Main Tank takes Faerlina. Off tank takes the adds</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="Heal">
						<ul>
							<li>Dispel Poison Bolt Volley</li>
						</ul>
					</wow-classic-information-card>
					<wow-classic-information-card role="DPS">
						<ul>
							<li>If you are doing 25-man, then always prioritize killing Naxxramas followers.</li>
						</ul>
					</wow-classic-information-card>
				</div>
			</template>
		</image-dropdown-component>
	</div>
</template>
<script>
	import ImageDropdownComponent from '../../static/ImageDropdownComponent.vue';
	import WowClassicInformationCard from '../WowInformationCard.vue';
	export default {
		components: {
			ImageDropdownComponent,
			WowClassicInformationCard,
		},
	};
</script>
